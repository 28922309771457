import React from "react"
import * as d3 from "d3";

const defaultHeight = 400
const defaultWidth = 400

const scale = d3.scaleOrdinal(d3.schemeTableau10);

function color(group) {
  return scale(group);
}

function drag(simulation) {
  function dragstarted(event, d) {
    if (!event.active) simulation.alphaTarget(0.3).restart();
    d.fx = d.x;
    d.fy = d.y;
  }
  
  function dragged(event,d) {
    d.fx = event.x;
    d.fy = event.y;
  }
  
  function dragended(event,d) {
    if (!event.active) simulation.alphaTarget(0);
    d.fx = null;
    d.fy = null;
  }
  
  return d3.drag()
    .on("start", dragstarted)
    .on("drag", dragged)
    .on("end", dragended);
}

function bound(x, lower, higher) {
  const smallest = lower + 10;
  const largest = higher - 10;
  if (x <= smallest) {
    return smallest;
  }
  if (x >= largest) {
    return largest;
  }
  return x;
}

function translate(element, d, sizeShape) {
  if (d.x > 0) {
    return 'translate(15, 5.5)';
  }

  const node = element.nodes().filter(n => n.innerHTML === d.title)[0]
  const title_width = node.getBBox().width
  const flipped_x = - title_width - sizeShape
  return `translate(${flipped_x}, 5.5)`;
}

class BlogGraph extends React.Component {
  constructor(props) {
  	super(props);
    this.state = {
    	nodes: props.nodes,
      links: props.links,
      width: props.width,
      height: props.height,
    };
  }

  componentDidMount() {

    var box_blog_graph = document.querySelector('.blog-graph');
    const width = box_blog_graph?.clientWidth || defaultWidth
    const height = box_blog_graph?.clientHeight || defaultHeight

    const links = this.state.links;
    const nodes = this.state.nodes;
    const nodes_tag = nodes.filter(d => d.group === 0 || d.group === 1)
    const nodes_post = nodes.filter(d => d.group >= 2)
    
    this.force = d3.forceSimulation(nodes)
      .force("link", d3.forceLink(links).id(d => d.id).distance(25))
      .force("charge", d3.forceManyBody().strength(-350))
      .force('collision', d3.forceCollide().radius((d) => {
        return d.title.length * 1.5;
      }))
      .force("center", d3.forceCenter(0, 0))
    
    const svg = d3.select(".blog-graph")
      // Container class to make it responsive.
      .classed("svg-container", true)
      // Responsive SVG needs these 2 attributes and no width and height attr.
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr("viewBox", [-width/2, -height/2, width, height])
      .attr("width", width)
      .attr("height", height)
      .classed("svg-content-responsive", true)

      .attr("xmlns", "http://www.w3.org/2000/svg")
      .attr("xlink", "http://www.w3.org/1999/xlink")
    
    const link = svg.append("g")
      .attr("stroke", "#999")
      .attr("stroke-opacity", 0.6)
      .selectAll("line")
      .data(links)
      .join("line")
      .attr("stroke-width", d => Math.sqrt(d.value));

    const node_post = svg.append("g")
      .attr("class", "blog-graph-node-group")
      .attr("stroke-width", 1.5)
      .selectAll("a")
      // posts
      .data(nodes_post)
      .join("a")
      .attr("xlink:href", d => d.id)
      .append("g");

    const node_post_sim = node_post
      .append("circle")
      .attr("r", 7)
      .attr("fill", d => color(d.group))
      .call(drag(this.force));
  
    node_post_sim
      .append("title")
      .text(d => d.id);

    const node_post_label_sim = node_post
      .append("text")
      .text(d => d.title)
      .attr("class", "blog-graph-text")
      .attr("stroke-width", 0.1)
      .attr('x', 14)
      .attr('y', 14)
      .call(drag(this.force));

  
    const node_tag = svg.append("g")
      .attr("class", "blog-graph-node-group")
      .attr("stroke-width", 1.5)
      .selectAll("a")
      // tags
      .data(nodes_tag)
      .join("a")
      .attr("xlink:href", d => d.id)
      .append("g")
    
    const node_tag_sim = node_tag
      .append("rect")
      .attr("width",14)
      .attr("height",14)
      .attr("rx", 3)
      .attr("ry", 3)
      .attr("fill", d => color(d.group))
      .call(drag(this.force));
    
    node_tag_sim
      .append("title")
      .text(d => d.id);
  
    const node_tag_label_sim = node_tag
      .append("text")
      .text(d => d.title)
      .attr("class", "blog-graph-text")
      .attr("stroke-width", 0.1)
      .attr('x', 14)
      .attr('y', 14)
      .call(drag(this.force));

    this.force.on("tick", () => {
      link
      .attr("x1", d => bound(d.source.x, -width/2, width/2))
      .attr("y1", d => bound(d.source.y, -height/2, height/2))
      .attr("x2", d => bound(d.target.x, -width/2, width/2))
      .attr("y2", d => bound(d.target.y, -height/2, height/2));

      node_post_sim
          .attr("cx", d => bound(d.x, -width/2, width/2))
          .attr("cy", d => bound(d.y, -height/2, height/2));

      node_post_label_sim
          .attr("x", d => bound(d.x, -width/2, width/2))
          .attr("y", d => bound(d.y, -height/2, height/2))
          .attr('transform', d => translate(node_post_label_sim, d, 14));

      node_tag_sim
          .attr("x", d => bound(d.x, -width/2, width/2))
          .attr("y", d => bound(d.y, -height/2, height/2))
          .attr('transform', 'translate(-7, -7)');
      
      node_tag_label_sim
          .attr("x", d => bound(d.x, -width/2, width/2))
          .attr("y", d => bound(d.y, -height/2, height/2))
          .attr('transform', d => translate(node_tag_label_sim, d, 14));
    });
  }

  componentWillUnmount() {
    this.force.stop();
  }


	render() {
    return (
      <>
      <svg 
        className="blog-graph"
        width="100%"
        height={this.props.height}
        xmlnsxl="http://www.w3.org/1999/xlink">
        <rect
          className="blog-graph-box"
          x="-50%"
          y={-this.props.height / 2}
          width="100%"
          height={this.props.height}
          rx="10"
          ry="10"
          fillOpacity="0%"
          stroke="#333333"
          strokeWidth="5"
          strokeOpacity="0.5"
          />
      </svg>
      <svg
        className="blog-graph-legend"
        width="100%"
        height="20">
        <g class="blog-graph-node-group">
          <rect x="2" y="2" rx="3" ry="3" width="14" height="14" fill={color(0)} stroke-width="1.5"/>
          <text x="20" y="15" class="blog-graph-text" stroke-width="0.1">Home page</text>
        </g>
        <g class="blog-graph-node-group">
          <rect x="132" y="2" rx="3" ry="3" width="14" height="14" fill={color(1)} stroke-width="1.5"/>
          <text x="150" y="15" class="blog-graph-text" stroke-width="0.1">Tags</text>
        </g>
        <g class="blog-graph-node-group">
          <circle cx="210" cy="9" r="7" fill={color(2)} stroke-width="1.5"/>
          <circle cx="221" cy="9" r="7" fill={color(3)} stroke-width="1.5"/>
          <text class="blog-graph-text" stroke-width="0.1" x="232" y="15">Posts by language</text>
        </g>
      </svg>
      <br/>
      </>
    );
  }
}

BlogGraph.defaultProps = {
  width: defaultWidth,
  height: defaultHeight,
};

export { BlogGraph }
