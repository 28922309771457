import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"
import { BlogPosts } from "../components/blogposts"

import { BlogGraph } from "../components/bloggraph"
import graph from '../../static/graph.json';

import { languageContext } from '../context/LanguageContext'
import { orderPostsByUserLanguages } from '../utils'


const BlogPage = () => {
  const blogPosts = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        edges {
          node {
            frontmatter {
              title
              date
              lang
              description
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  const nodes = blogPosts.allMarkdownRemark.edges.map(e => e.node)
  const { userLanguages } = React.useContext(languageContext);

  const orderedNodes = orderPostsByUserLanguages(
    nodes, 
    userLanguages || ["en"]
  );

  return (
    <Layout>
      <SEO title="Blog" />
      <h1>Blog</h1>

      <h2 key={`listing`}>Listing</h2>
        {orderedNodes
          .map(([lang, nodes]) => {
            return (
              <BlogPosts 
                lang={lang}
                nodes={nodes}
              />
            )
          })
        }
      <h2 key={`graph`}>Graph</h2>
      <BlogGraph nodes={graph.nodes} links={graph.links}/>
    </Layout>
  )
}

export default BlogPage
